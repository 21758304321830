<template>
    <div>
        <div id="wrapper">
            <v-app>
                <v-main>
                    <router-view />
                </v-main>
            </v-app>
        </div>
        <div>
            <div id="bg"/>
        </div>
    </div>
</template>
<script>
export default {
    name: "DashboardCoreView",

    data: () => ({}),

    computed: {},
};
</script>
